/*  Theme Name: swapnil | Responsive Bootstrap Landing Template Red
    Author: swapnil
    Version: 1.1.0
    File Description: Main CSS file of the template
*/


.text-custom,.read-btn,
.navbar-custom.navbar-white .navbar-nav li.active a,
.navbar-custom.navbar-white .navbar-nav li a:hover,
.navbar-custom.navbar-white .navbar-nav li a:focus,
.navbar-custom.navbar-white .navbar-nav li a:active,
.play-btn,.blog-title:hover,.social-icon:hover i{
    color: #ee6352 !important;
}


.section-title-border, .bg-custom, .btn-custom,.features-item-list li:before,.process-left-icon-1 i,
.process-left-icon-2 i{
    background-color: #ee6352;
}



.btn-custom,.play-btn{
    border-color:#ee6352 !important;
}

.btn-custom:hover,
.btn-custom:focus,
.btn-custom:active,
.btn-custom.active,
.btn-custom.focus,
.btn-custom:active,
.btn-custom:focus,
.btn-custom:hover,
.open > .dropdown-toggle.btn-custom{
	background-color: #dc5342;
}


.btn-custom:hover,
.btn-custom:focus,
.btn-custom:active,
.btn-custom.active,
.btn-custom.focus,
.btn-custom:active,
.btn-custom:focus,
.btn-custom:hover,
.open > .dropdown-toggle.btn-custom{
	border-color: #dc5342 !important;
}



